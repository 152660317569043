import { Table, TableBody, TableCell, TableHead, TableRow, Skeleton } from '@mui/material'

const TableSkeleton = ({ tableHeadLabels }: any) => {
    return (

        <Table stickyHeader className="mb-0">
            <TableHead>
                <TableRow>

                    {tableHeadLabels.map((headCell: string, i: number) => (
                        <TableCell
                            key={i}
                        >
                            {headCell}
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {[...Array(10)].map((_, i: number) => {
                    return (
                        <TableRow key={i}>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>
                            <TableCell>
                                <Skeleton variant="text" />
                            </TableCell>

                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>

    )
}

export default TableSkeleton