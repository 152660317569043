import * as firebase from "firebase/compat/app";
import {
	createUserWithEmailAndPassword,
	getAuth,
	onAuthStateChanged,
	signInWithEmailAndPassword,
	signOut,
	updateProfile,
} from "firebase/auth";
import { environments } from "../../enviroment";

const getAuthentication = () => {
	const app = firebase.default.initializeApp({
		...environments.FIREBASE_CONFIG,
	});
	const auth = getAuth(app);
	return auth;
};

export const login = async (email: string, password: string) => {
	const auth = getAuthentication();
	return signInWithEmailAndPassword(auth, email, password);
};

export const createUser = async (
	name: string,
	email: string,
	password: string,
) => {
	const auth: any = getAuthentication();
	createUserWithEmailAndPassword(auth, email, password).then(
		(userCred: any) => {
			const user = userCred.user;
			if (user) {
				updateUser(name)
					.then((userCred: any) => {
						console.log("user created", userCred);
					})
					.catch((error: any) => {
						console.log(error);
					});
			}
		},
	);
};

export const updateUser = async (name: string) => {
	const auth: any = getAuthentication();

	updateProfile(auth.currentUser, {
		displayName: name,
	})
		.then((userCred: any) => {
			console.log("profile updated", userCred);
		})
		.catch((error: any) => {
			console.log("cannot update user");
		});
};

export const isUserLogin = () => {
	const auth: any = getAuthentication();
	onAuthStateChanged(auth, (user: any) => {
		if (user?.currentUser) {
			console.log(user.currentUser);
			return true;
		}
		return false;
	});
};

export const logout = async () => {
	const auth = getAuthentication();
	signOut(auth)
		.then(() => {
			console.log("user sign out");
		})
		.catch((err) => {
			console.log("error", err);
		});
};
