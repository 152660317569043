// ----------------------------------------------------------------------

const account = {
    displayName: "Prasiddhi Kohade",
    email: "prasiddhi.kohade@innovect.com",
    photoURL: "/assets/images/avatars/avatar_default.jpg",
    role: "admin",
};

export default account;
