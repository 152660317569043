import * as firebase from "firebase/compat/app";
import { getFirestore, collection, query, getDocs } from "firebase/firestore";
import _ from "lodash";
import { environments } from "../enviroment";
import { Employee } from "../models/employee.model";
import { Skill } from "../models/skill.model";

interface Filter {
	id: number;
	label: string;
	value: string;
}
interface TopSkills {
	skill: string;
	count: number;
	employees: string[];
}

let employeesList: Employee[] = [];

export const getEmployeeSkills = async () => {
	const app = firebase.default.initializeApp({
		...environments.FIREBASE_CONFIG,
	});

	let employees: Employee[] = [];
	const db = getFirestore(app);
	const q = query(collection(db, environments.COLLECTION));
	const querySnapshot = await getDocs(q);
	querySnapshot.docs.forEach((doc: any) => {
		employees?.push(doc.data());
	});
	employeesList = structuredClone(employees);
	// getTopAspiringSkills(employees)
	return employees;
};

const getTopAspiringSkills = (skills: Skill[]) => {
	// let topSkills: any[] = [{ skill: "", count: 0, employees: [] }];

	let arr: any[] = [];
	skills.forEach((s, i) => {
		let topSkills: TopSkills = {
			skill: "",
			count: 0,
			employees: [],
		};
		topSkills.skill = s.name;
		let count = 0;
		employeesList.forEach((emp: Employee) => {
			emp.skillAndExpertise?.skillsToLearn.forEach((skill: Skill) => {
				if (skill.name === s.name) {
					count++;
					topSkills.employees.push(emp.personalInfo.name);
				}
			});
		});
		topSkills.count = count;
		arr.push(topSkills);
	});

	// const sorted = arr.sort((a, b) => {
	// 	return b.count - a.count;
	// });
	// const blob = new Blob([JSON.stringify(sorted)], {
	// 	type: "application/json",
	// });
	// saveAs(blob, "topAspiringSkills.json");
};

export const getSkillToken = (employeeList: Employee[]) => {
	let tempEmployee: Employee[] = structuredClone(employeeList);
	const merge0 = tempEmployee.map((emp: Employee) => {
		return _.merge(emp.skillAndExpertise?.expertise);
	});
	const merge1 = tempEmployee.map((emp: Employee) => {
		return _.merge(emp.skillAndExpertise?.knownSkills);
	});
	const merge2 = tempEmployee.map((emp: Employee) => {
		return _.merge(emp.skillAndExpertise?.skillsToLearn);
	});

	const merge = [...merge0, ...merge1, ...merge2];

	const skills: Skill[] = _.uniqBy(merge.flat(), (skill: Skill) => {
		return skill.name;
	});
	let uniqAspiringSkills: any = _.uniqBy(merge2.flat(), (skill: Skill) => {
		return skill.name;
	});

	getTopAspiringSkills(uniqAspiringSkills);
	return skills;
};

export const filterData = async (filters: Filter[], skills: Skill[]) => {
	let filteredEmployees: Employee[] = [];
	if (filteredEmployees) {
		employeesList.forEach((emp: any) => {
			filters.forEach((filter: Filter) => {
				emp["skillAndExpertise"][filter.value].forEach((val: any) => {
					skills.forEach((skill: any) => {
						if (val.name === skill.name) {
							filteredEmployees.push(emp);
						}
					});
				});
			});
		});
	}

	filteredEmployees = _.unionWith(filteredEmployees, _.isEqual);

	return filteredEmployees;
};

export const getEmployeeList = (employees: Employee[]) => {
	const employeeList = employees.map((emp: Employee) => {
		return emp.personalInfo;
	});
	return employeeList;
};
