export const environments = {
	FIREBASE_CONFIG: {
		apiKey: "AIzaSyAWOx2t4ljtaUiFIOW_BIlgjLwCXbOFeh8",
		authDomain: "simpayx.firebaseapp.com",
		databaseURL: "https://simpayx.firebaseio.com",
		projectId: "simpayx",
		storageBucket: "simpayx.appspot.com",
		messagingSenderId: "263999792826",
		appId: "1:263999792826:web:cf202ca8daeda397d1c483",
		measurementId: "G-HMRZD6S0X0",
	},
	COLLECTION: "erp-employee-skills",
	SECRET_KEY: "112aF13G",
};
