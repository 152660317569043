import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SecureLS from 'secure-ls'
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../utils/components/iconify';
import { login } from '../../../shared/services/authentication.service';
import { LOGIN_CONSTANTS } from '../../../shared/constants/login.constants';


interface LoginError {
    error: boolean,
    msg: string
}

const LoginForm = () => {
    const navigate = useNavigate();

    const ls = new SecureLS()
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState<Partial<LoginError>>({})
    const [loginProgress, setLoginProgress] = useState<boolean>(false)



    const handleLogin = () => {
        // navigate('/dashboard', { replace: true });
        setLoginProgress(true)
        login(email, password).then((userCredential: any) => {
            const user = userCredential.user;
            ls.set(LOGIN_CONSTANTS.USER, user)
            setLoginProgress(false)
            navigate('/innovect-portal/dashboard/landing', { replace: true });


        })
            .catch((err: any) => {
                setLoginProgress(false)
                const errorCode = err.code;
                setLoginError({
                    error: true,
                    msg: errorCode
                })
            });
    };


    return (
        <>
            <Stack sx={{ p: 1 }} spacing={3}>
                <TextField name="email" label="Email address" onChange={(e: any) => {
                    setEmail(e.target.value)
                }} />

                <TextField
                    name="password"
                    label="Password"
                    type={showPassword ? 'text' : 'password'}
                    error={loginError.error}
                    helperText={loginError.error ? loginError.msg : ''}
                    onChange={(e: any) => {
                        setPassword(e.target.value)
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </Stack>

            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
                {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Label" /> */}
                <Link variant="subtitle2" underline="hover">
                    Forgot password?
                </Link>
            </Stack>

            <LoadingButton fullWidth size="large" type="submit" loadingIndicator="Loading…" variant="contained" onClick={handleLogin} loading={loginProgress}>
                Login
            </LoadingButton>
        </>
    )
}

export default LoginForm