import './App.css';
import ThemeProvider from './theme';
import Router from "./router/Router"
import ScrollToTop from './utils/components/ScrollToTop';



const App = () => {
  return (
    <ThemeProvider>
      <ScrollToTop />
      <Router />
    </ThemeProvider>
  );
}



export default App;
