// component
import SvgColor from "../../../utils/components/svg-color";

// ----------------------------------------------------------------------

const icon = (name) => (
	<SvgColor
		src={`/assets/icons/navbar/${name}.svg`}
		sx={{ width: 1, height: 1 }}
	/>
);

const navConfig = [
	{
		title: "dashboard",
		path: "landing",
		icon: icon("ic_analytics"),
	},
	{
		title: "employee skills",
		path: "employee-skills",
		icon: icon("ic_user"),
	},
];

export default navConfig;
