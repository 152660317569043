
import { Container, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Logo from '../utils/components/logo'
import useResponsive from '../hooks/useResponsive';
import { LoginForm } from '../components/auth/login';


const StyledRoot = styled('div')(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        display: 'flex',
    },
}));

const StyledSection = styled('div')(({ theme }) => ({
    width: '100%',
    maxWidth: 480,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    // boxShadow: theme.customShadows.card,
    backgroundColor: theme.palette.background.default,
}));

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));
const LoginPage = () => {
    const mdUp = useResponsive('up', 'md', 'down')

    return (
        <>
            <StyledRoot>
                {mdUp && (
                    <StyledSection>
                        <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
                            Hi, Welcome Back
                        </Typography>
                        <img src="/assets/illustrations/illustration_login.png" alt="login" />
                    </StyledSection>
                )}

                <Container maxWidth="sm">
                    <StyledContent>
                        <Typography variant="h4" gutterBottom>
                            Sign in to Portal
                        </Typography>


                        <Stack direction="row" spacing={2} sx={{
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <Logo />
                        </Stack>
                        <LoginForm />
                        {/* <SignupForm /> */}
                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    )
}

export default LoginPage