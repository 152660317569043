import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
// @mui
import { Box, Link } from "@mui/material";

// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, sx, ...other }, ref) => {
	// const PRIMARY_LIGHT = theme.palette.primary.light;

	// const PRIMARY_MAIN = theme.palette.primary.main;

	// const PRIMARY_DARK = theme.palette.primary.dark;

	// OR using local (public folder)
	// -------------------------------------------------------
	const logo = (
		<Box
			component='img'
			src={require("./logo192.png")}
			sx={{
				p: 2,
				cursor: "pointer",
				// borderRadius: "1em",
				width: 120,
				height: 120,
				alignItems: "center",
				justifyContent: "center",
				justifyItems: "center",
				borderRadius: "50%",
				// background: "black",
				...sx,
			}}
		/>
	);

	// const logo = (
	// 	<Box
	// 		ref={ref}
	// 		component='div'
	// 		sx={{
	// 			width: 40,
	// 			height: 40,
	// 			display: "inline-flex",
	// 			...sx,
	// 		}}
	// 		{...other}>
	// 		<svg
	// 			version='1.0'
	// 			xmlns='http://www.w3.org/2000/svg'
	// 			width='592.000000pt'
	// 			height='80.000000pt'
	// 			viewBox='0 0 592.000000 80.000000'
	// 			preserveAspectRatio='xMidYMid meet'>
	// 			<g
	// 				transform='translate(0.000000,80.000000) scale(0.100000,-0.100000)'
	// 				fill='#000000'
	// 				stroke='none'></g>
	// 		</svg>
	// 	</Box>
	// );

	if (disabledLink) {
		return <>{logo}</>;
	}

	return (
		<Link to='/' component={RouterLink} sx={{ display: "contents" }}>
			{logo}
		</Link>
	);
});

Logo.propTypes = {
	sx: PropTypes.object,
	disabledLink: PropTypes.bool,
};

export default Logo;
