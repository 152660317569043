import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { getEmployeeSkillsReducer } from "./reducer/employeeReducer";
import thunk from "redux-thunk";

const reducer = combineReducers({
	getEmployeeSkills: getEmployeeSkillsReducer,
});

const initialState = {};

const middleware = [thunk];

const store = createStore(
	reducer,
	initialState,
	composeWithDevTools(applyMiddleware(...middleware)),
);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
export type AppSubscribe = ReturnType<typeof store.subscribe>;

export default store;
