import { useState, useEffect } from 'react';
import { Navigate, useNavigate, useRoutes } from 'react-router-dom';
import SecureLS from 'secure-ls'
import DashboardLayout from '../layouts/Dashboard/DashboardLayout';
import SimpleLayout from '../layouts/simple/SimpleLayout';
import DashboardPage from '../pages/DashboardPage';
import EmployeeSkills from '../pages/EmployeeSkills';
import LoginPage from '../pages/LoginPage';
import Page404 from '../pages/Page404';
import { LOGIN_CONSTANTS } from '../shared/constants/login.constants';

const Routes = () => {
    const ls = new SecureLS()
    const navigate = useNavigate();
    const [user] = useState(ls.get(LOGIN_CONSTANTS.USER))

    useEffect(() => {
        if (Object.keys(user).length > 0) {
            navigate('/innovect-portal/dashboard/landing', { replace: true });
        }
    }, [user])


    const routes = useRoutes([
        {
            path: 'innovect-portal/dashboard',
            element: <DashboardLayout />,
            children: [
                { element: <Navigate to="innovect-portal/dashboard/landing" /> },
                { path: 'landing', element: <DashboardPage /> },
                { path: 'employee-skills', element: <EmployeeSkills /> },
            ]
        },
        {
            path: '/',
            element: <Navigate to="/innovect-portal/dashboard" />,
            index: true
        },
        {
            path: 'innovect-portal/login',
            element: <LoginPage />,
            index: true
        },
        {
            element: <SimpleLayout />,
            children: [
                { element: <Navigate to="innovect-portal/dashboard" />, index: true },
                { path: '404', element: <Page404 /> },
                { path: '*', element: <Navigate to="/404" /> },
            ],
        },
        {
            path: '*',
            element: <Navigate to="/404" replace />,
        },
    ]);
    return routes


}



export default Routes