import { Employee } from "../../models/employee.model";
import { Skill } from "../../models/skill.model";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver";

export const downloadCsvFile = async (employee: Employee[]) => {
	let headers: string[] = [
		"sr.no",
		"Name",
		"Email",
		"Project",
		"Project Joined Date",
		"Area of Expertise",
		"Known Skills",
		"Skills aspire to learn",
		"reason",
		"Certification or Trainings",
		"Willing to train employees",
		"Topics",
	];
	const title = `Employee Skills`;
	let workbook = new Workbook();
	let employeeCsv = employee.reduce((acc: any, employee: Employee, i: any) => {
		const { personalInfo, skillAndExpertise, trainingAndCertification } =
			employee;
		acc.push([
			`${i + 1}`,
			`${personalInfo.name}`,
			`${personalInfo.email}`,
			`${personalInfo.empProject}`,
			`${personalInfo.projectJoiningDate}`,
			`${skillAndExpertise.expertise.reduce(
				(acc1: any, skill: Skill, i: number) => {
					acc1.push([
						`${skill.name === "Add" ? skill.otherSkill : skill.name}`,
					]);
					return acc1;
				},
				[],
			)}`,
			`${skillAndExpertise.knownSkills.reduce(
				(acc1: any, skill: Skill, i: number) => {
					acc1.push([
						`${skill.name === "Add" ? skill.otherSkill : skill.name}`,
					]);
					return acc1;
				},
				[],
			)}`,
			`${skillAndExpertise.skillsToLearn.reduce(
				(acc1: any, skill: Skill, i: number) => {
					acc1.push([
						`${skill.name === "Add" ? skill.otherSkill : skill.name}`,
					]);
					return acc1;
				},
				[],
			)}`,
			`${skillAndExpertise.reason}`,
			`${trainingAndCertification.question1}`,
			`${trainingAndCertification.question2}`,
			`${trainingAndCertification.question3}`,
		]);
		return acc;
	}, []);

	let workSheet = workbook.addWorksheet("Employee_Skills");

	let titleRow = workSheet.addRow([title]);

	titleRow.font = {
		name: "Roboto sans-serif",
		family: 4,
		size: 12,
		bold: true,
	};

	let headerRow = workSheet.addRow(headers);
	headerRow.eachCell((cell: any, number: any) => {
		cell.fill = {
			type: "pattern",
			pattern: "solid",
			fgColor: { argb: "FFFFFF00" },
			bgColor: { argb: "FF0000FF" },
		};
		cell.border = {
			top: { style: "thin" },
			left: { style: "thin" },
			bottom: { style: "thin" },
			right: { style: "thin" },
		};
	});

	workSheet.getColumn(2).width = 25;
	workSheet.getColumn(3).width = 30;
	workSheet.getColumn(4).width = 25;
	workSheet.getColumn(5).width = 20;

	workSheet.getColumn(6).width = 30;
	workSheet.getColumn(7).width = 30;
	workSheet.getColumn(8).width = 30;
	workSheet.getColumn(9).width = 30;

	workSheet.getColumn(10).width = 30;
	workSheet.getColumn(11).width = 30;
	workSheet.getColumn(12).width = 30;

	employeeCsv.forEach((employee: Employee) => {
		workSheet.addRow(employee);
	});

	workbook.xlsx.writeBuffer().then((data: any) => {
		let blob = new Blob([data], {
			type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		});
		saveAs(blob, "Employee_Skills.xlsx");
	});

	workbook.removeWorksheet("Employee_Skills");
};
