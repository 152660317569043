import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Button, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, Dialog, DialogContent, DialogContentText, DialogActions } from '@mui/material';
// mocks_
import SecureLS from 'secure-ls'
import account from '../../../_mock/account';
import { logout } from '../../../shared/services/authentication.service';
import { LOGIN_CONSTANTS } from '../../../shared/constants/login.constants';
import { useNavigate } from 'react-router-dom';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {

  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [openDialogue, setDialogue] = useState(false);
  const ls = new SecureLS()
  const [user] = useState(ls.get(LOGIN_CONSTANTS.USER))

  const handleOpen = (event: any) => {
    setOpen(event.currentTarget);
  };

  const handleOpenDialogue = (event: any) => {
    setDialogue(event.currentTarget)
  }
  const handleLogout = () => {

    logout().then(() => {
      ls.remove(LOGIN_CONSTANTS.USER)
      navigate('/innovect-portal/login', { replace: true });
    })
    setOpen(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleCloseDialogue = () => {
    setDialogue(false)
  }

  return (
    <>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme: any) => alpha(theme.palette.grey[900], 0.8),
            },
          }) as any,
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleOpenDialogue} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </Popover>
      <Dialog
        open={openDialogue}
        onClose={handleCloseDialogue}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {"Yor are about to Logout,Are you sure?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogout}>Logout</Button>
          <Button onClick={handleCloseDialogue} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
