import {
	EMPLOYEE_SKILLS_FAIL,
	EMPLOYEE_SKILLS_REQUEST,
	EMPLOYEE_SKILLS_SUCCESS,
} from "../constants/employeeConstants";

export const getEmployeeSkillsReducer = (state: any = {}, action: any) => {
	switch (action.type) {
		case EMPLOYEE_SKILLS_REQUEST:
			return { loading: true };

		case EMPLOYEE_SKILLS_SUCCESS:
			return { loading: false, employees: action.payload };

		case EMPLOYEE_SKILLS_FAIL:
			return { loading: false, error: action.payload };

		default:
			return state;
	}
};
