import React, { useEffect, useState } from 'react'

import { Card, Chip, Toolbar, Autocomplete, } from '@mui/material'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Iconify from '../utils/components/iconify';
import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField';
import { Stack } from '@mui/system';
import { filterData, getEmployeeSkills, getSkillToken } from '../api/employeeSkills';
import { Skill } from '../models/skill.model';
import { Employee } from '../models/employee.model';
// import _ from 'lodash'
import { downloadCsvFile } from '../shared/services/downloadFile.service';
import TableSkeleton from '../components/skeleton/TableSkeleton';
const FilteredEmployeeList = React.lazy(() => import('../components/FilteredEmployeeList'))


interface Filter {
    id: number,
    label: string,
    value: string
}

const EmployeeSkills = () => {
    const StyledRoot = styled(Toolbar)(({ theme }: any) => ({
        height: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        padding: theme.spacing(0, 1, 0, 3),
    }));


    const filter: Filter[] = [
        {
            id: 1,
            label: "Primary Skills",
            value: "expertise"
        },
        {
            id: 2,
            label: "Secondary Skills",
            value: "knownSkills"
        },
        {
            id: 3,
            label: "Aspiring Skills",
            value: "skillsToLearn"
        },

    ]

    const TableHeadLabel: string[] = [
        'Name', 'Project', 'Primary Skills', 'Secondary Skills', 'Aspiring Skills', 'Actions'
    ]



    // const [type, setType]: any = useState<Partial<Filter>>()
    const [filterSuggestions]: any = useState<Partial<Filter[]>>([...filter])
    // const [skillSuggestions, setSkillSuggestions]: any = useState<Partial<Skill[]>>([])
    const [loading, setLoading] = useState<Partial<Boolean>>(true)
    const [employees, setEmployees]: any = useState<Partial<Employee[]>>([])
    const [employeesResult, setEmployeesResult]: any = useState<Partial<Employee[]>>([])
    const [employeeSuggestionList, setEmployeeSuggestionList]: any = useState<Partial<Employee[]>>([])
    const [skillQueries, setSkillQueries]: any = useState<Partial<Skill[]>>([])
    const [typeQueries, setTypeQueries]: any = useState<Partial<Filter[]>>([])
    const [skillsSuggestions, setSkillsSuggestions]: any = useState<Partial<Skill[]>>([])
    const [employeeNameQuery, setEmployeeNameQuery] = useState('')
    const [skillQuery, setSkillQuery] = useState('')

    useEffect(() => {
        getEmployeeSkills().then((res) => {
            if (res.length > 0) {
                setEmployeeSuggestionList([...res])
                setEmployeesResult([...res])
                setEmployees([...res])
                setLoading(false)
                let result = getSkillToken(res)
                setSkillsSuggestions([...result])

            }
        })
    }, [])

    setTimeout(async () => {
        const close = await document?.getElementsByClassName(
            "MuiAutocomplete-clearIndicator"
        )[0];
        close?.addEventListener("click", () => {

            setEmployeesResult([...employees])
            setEmployeeNameQuery('')
        });
    }, 100);

    const handleDeleteType = (t: Filter) => {
        const idx = typeQueries.indexOf(t)
        if (idx > -1) {
            typeQueries.splice(idx, 1);
            setTypeQueries([...typeQueries])
            filterSuggestions.push(t)
        }
    }
    const handleDeleteSkills = (skill: Skill) => {
        const idx = skillQueries.indexOf(skill)
        if (idx > -1) {
            skillQueries.splice(idx, 1);
            setSkillQueries([...skillQueries])
            skillsSuggestions.push(skill)
        }
    }

    const chooseType = (e: any, val: any) => {
        // setType(val)

        if (val?.value.length > 0) {
            setTypeQueries([...typeQueries, val])
            const idx = filterSuggestions.indexOf(val)
            if (idx > -1) {
                filterSuggestions.splice(idx, 1)
            }
        }
    }
    const chooseSkills = (e: any, val: any) => {
        // setSkill(val)

        if (val?.name.length > 0) {
            setSkillQueries([...skillQueries, val])
            setSkillQuery(val)
            const idx = skillsSuggestions.indexOf(val)
            if (idx > -1) {
                skillsSuggestions.splice(idx, 1)
            }
        }

    }

    const searchEmployee = (e: any, val: any) => {
        console.log(val);
        if (val) {
            setEmployeesResult([val])
            setEmployeeNameQuery(val.personalInfo.name)
        }
    }

    const filterDataHandler = () => {
        filterData(typeQueries, skillQueries).then((res) => {
            setEmployeesResult([...res])
        })
    }
    const downloadCSV = () => {
        downloadCsvFile(employees).then(() => {
            console.log("Download Success");
        })
    }

    return (
        < Card >
            <StyledRoot>
                <Grid container sx={{ p: 2 }}>
                    <Grid item xs={3}>
                        <Stack spacing={2}>
                            <Grid container  >
                                <Grid item xs={10}>
                                    <Autocomplete
                                        freeSolo
                                        id="column"
                                        size='small'
                                        inputValue={employeeNameQuery.length > 0 ? employeeNameQuery : undefined}
                                        options={employeeSuggestionList}
                                        getOptionLabel={(option: any) => typeof option.personalInfo?.name === 'string'
                                            || option.personalInfo?.name instanceof String ? option.personalInfo?.name : ""}
                                        // options={filter.map((option) => option)}
                                        onChange={searchEmployee}
                                        renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label="Search Employee"

                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <IconButton onClick={selectMultipleTypes} >
                                        <Iconify icon="ic:add" />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        </Stack>


                    </Grid>
                    <Grid item xs={3}>
                        <Stack spacing={2}>
                            <Grid container  >
                                <Grid item xs={10}>
                                    <Autocomplete
                                        freeSolo
                                        id="column"
                                        disableClearable
                                        size='small'
                                        autoComplete={true}
                                        options={filterSuggestions}

                                        getOptionLabel={(option: any) => option.label}
                                        // options={filter.map((option) => option)}
                                        onChange={chooseType}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Column Filter"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <IconButton onClick={selectMultipleTypes} >
                                        <Iconify icon="ic:add" />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        </Stack>

                        <Stack spacing={2} >

                            <Grid container sx={{ pt: 1 }} >
                                {typeQueries?.length > 0 &&
                                    <Grid item>
                                        {
                                            typeQueries?.map((t: any) => {
                                                return (
                                                    <Chip key={t.id} sx={{ mt: 1 }} label={t.label} onDelete={() => handleDeleteType(t)}></Chip>
                                                )
                                            })
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Stack>
                    </Grid>
                    <Grid item xs={3}>
                        <Stack spacing={2}>

                            <Grid container  >
                                <Grid item xs={10} >

                                    <Autocomplete
                                        freeSolo
                                        id="skill"
                                        disableClearable
                                        size='small'
                                        autoComplete={true}
                                        options={skillsSuggestions}
                                        inputValue={skillQuery.length > 0 ? skillQuery : undefined}
                                        getOptionLabel={(option: any) => option.name}
                                        // options={filter.map((option) => option)}
                                        onChange={chooseSkills}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Skill"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    type: 'search',
                                                }}
                                            />
                                        )}
                                    />
                                </Grid>
                                {/* <Grid item>
                                    <IconButton onClick={selectMultipleSkillsQueries}>
                                        <Iconify icon="ic:add" />
                                    </IconButton>
                                </Grid> */}
                            </Grid>
                        </Stack>
                        <Stack spacing={2} >

                            <Grid container sx={{ pt: 1 }} >
                                {skillQueries?.length > 0 &&
                                    <Grid item>
                                        {
                                            skillQueries?.map((t: any) => {
                                                return (
                                                    <Chip key={t.id} sx={{ mt: 1 }} label={t.name} onDelete={() => handleDeleteSkills(t)}></Chip>
                                                )
                                            })
                                        }
                                    </Grid>
                                }
                            </Grid>
                        </Stack>
                    </Grid>

                    <Grid item xs={1}  >
                        <Button startIcon={<Iconify icon="eva:search-fill" />} size="medium" type="submit" variant="outlined" onClick={() => filterDataHandler()} disabled={!(typeQueries.length > 0 && skillQueries.length > 0)}>
                            Search
                        </Button>
                    </Grid>

                    <Grid item xs={2} sx={{ pl: 4 }}>
                        <Button startIcon={<Iconify icon="eva:download-fill" />} size="medium" type="submit" variant="outlined" onClick={downloadCSV}>
                            Download
                        </Button>
                    </Grid>


                </Grid>
            </StyledRoot>
            {
                loading ?
                    <TableSkeleton tableHeadLabels={TableHeadLabel} /> : <FilteredEmployeeList tableHeadLabels={TableHeadLabel} employees={employeesResult} />

            }
        </ Card >
    )
}

export default EmployeeSkills